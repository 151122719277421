var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('complex-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.data,"search-config":_vm.searchConfig,"selects":_vm.selects,"pagination-config":_vm.paginationConfig},on:{"update:selects":function($event){_vm.selects=$event},"search":_vm.search},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('el-button-group',[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.create()}}},[_vm._v(_vm._s(_vm.$t('commons.button.create')))]),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.search()}}},[_vm._v(_vm._s(_vm.$t('commons.button.refresh')))]),_c('el-button',{attrs:{"size":"small","disabled":_vm.selects.length===0},on:{"click":function($event){return _vm.del()}}},[_vm._v(_vm._s(_vm.$t('commons.button.delete')))])],1)]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"selection","fix":""}}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('setting.table.registry.arch'),"mix-width":"80","fix":"","prop":"architecture"}}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('setting.table.registry.protocol'),"mix-width":"30","prop":"protocol"}}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('setting.table.registry.hostname'),"min-width":"100","prop":"hostname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{staticStyle:{"font-size":"12px"},attrs:{"type":"info"},on:{"click":function($event){return _vm.goForNexus(row)}}},[_vm._v(_vm._s(row.hostname))])]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('commons.table.status'),"min-width":"100","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status ==='Success')?_c('div',[_c('span',{staticClass:"iconfont iconduihao",staticStyle:{"color":"#32B350"}}),_vm._v(" "+_vm._s(_vm.$t("commons.status.normal"))+" ")]):_vm._e(),(row.status === 'Failed')?_c('div',[_c('span',{staticClass:"iconfont iconerror",staticStyle:{"color":"#FA4147"}}),_vm._v(" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.message,"placement":"top"}},[_c('el-link',{attrs:{"type":"info"}},[_vm._v(_vm._s(_vm.$t("commons.status.failed")))])],1)],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('commons.table.create_time'),"prop":"updatedAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("datetimeFormat")(row.updatedAt))+" ")]}}])}),_c('fu-table-operations',{attrs:{"buttons":_vm.buttons,"label":_vm.$t('commons.table.action'),"fix":""}})],1),_c('el-dialog',{staticClass:"ko-tips",attrs:{"title":_vm.$t('commons.message_box.prompt'),"show-close":true,"visible":_vm.tipsVisible,"width":"40%"},on:{"update:visible":function($event){_vm.tipsVisible=$event}}},[_c('complex-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tipstableData}},[_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('commons.table.name'),"width":"180"}}),_c('el-table-column',{attrs:{"prop":"value","label":_vm.$t('commons.table.description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [([row.value].toString().indexOf('http') != -1)?_c('el-link',{staticStyle:{"font-size":"12px"},attrs:{"type":"info"},on:{"click":function($event){return _vm.goToNexus(row.value)}}},[_vm._v(_vm._s(row.value))]):_c('span',[_vm._v(_vm._s(row.value))])]}}])})],1),_c('br'),_c('div',{staticStyle:{"line-height":"150%"}},[_c('span',{staticStyle:{"font-weight":"bolder"}},[_vm._v(_vm._s(_vm.$t('manifest.see_documentation'))+":")]),_c('br'),_c('a',{staticStyle:{"color":"#447DF7"},attrs:{"href":"https://kubeoperator.io/docs/user_manual/system_management/","target":"_blank"}},[_vm._v("https://kubeoperator.io/docs/user_manual/system_management/")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }